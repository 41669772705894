<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#29807c"
      spinner="spinner" />
    <v-layout class="hidden-md-and-up justify-center wrap" v-if="list.length > 0">
      <v-flex pa-2 v-for="(item, i) in list" :key="i" xs12 align-self-center>
        <v-card style="border-radius: 1px">
          <v-layout wrap justify-center>
            <v-flex text-center xs12 align-self-center>
              <v-avatar size="80px">
                <v-img :src="mediaURLnew + item.categoryId.icon"></v-img>
              </v-avatar>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12 class="dmsansregular text-center" style="color: black; font-size: 12px; font-weight: 600">
                  Category

                  - {{ item.categoryId.name }}
                </v-flex>
                <v-flex xs12 class="dmsansregular text-center" style="color: black; font-size: 16px; font-weight: 500">
                  {{ item.itemNames }}
                </v-flex>
                <v-flex xs12 class="dmsansregular text-center" style="color: black; font-size: 14px; font-weight: 400">
                  Order Date - {{ formatDate(item.create_date) }}
                </v-flex>
                <v-flex xs12 class="dmsansregular text-center" style="color: black; font-size: 14px; font-weight: 400">
                  Weight-
                  {{ item.weight
                  }}
                  {{ item.weightType
                  }}
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs12>
              <v-layout wrap justify-end>
                <v-flex xs12 class="text-center dmsansregular" style="color: black; font-size: 16px; font-weight: 500">
                  Status - {{ item.status
                  }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-layout pt-4 pb-12 wrap justify-center>
          <v-flex xs12 align-self-center>
            <div>
              <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                v-model="currentPage" color="#005f32"></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class=hidden-sm-and-down justify-center wrap v-if="list.length > 0">
      <v-flex pb-4 v-for="(item, i) in list" :key="i" xs10 pl-3 pr-6 align-self-center>
        <v-card style="border-radius: 1px">
          <v-layout wrap justify-center>
            <v-flex text-center xs12 lg1 pl-6 pt-7 pb-7 align-self-center>
              <v-avatar size="80px">
                <v-img :src="mediaURLnew + item.categoryId.icon">
                </v-img>
              </v-avatar>
            </v-flex>
            <v-flex xs11 lg5 pl-lg-14 pt-lg-5>
              <v-layout wrap>
                <v-flex pt-4 class="dmsansregular" style="color: black; font-size: 12px; font-weight: 600">Category
                  - {{ item.categoryId.name }}</v-flex>
                <v-flex xs12 class="dmsansregular" style="color: black; font-size: 16px; font-weight: 500">{{
                  item.itemNames }}</v-flex>
                <v-flex xs12 class="dmsansregular" style="color: black; font-size: 14px; font-weight: 400">
                  Order Date -
                  {{
                    formatDate(item.create_date) }}</v-flex>
                <v-flex xs12 class="dmsansregular" style="color: black; font-size: 14px; font-weight: 400">
                  Weight-
                  {{ item.weight
                  }}
                  {{ item.weightType
                  }}</v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs11 lg6 pt-lg-7>
              <v-layout wrap justify-end pl-lg-10>
                <v-flex pt-1 pl-2 xs12 lg7 text-left class="dmsansregular"
                  style="color: black; font-size: 16px; font-weight: 500">
                  Status- {{ item.status }}
                </v-flex>
                <v-flex xs9 pl-13 pt-2>
                  <v-btn dark color="#13736f" width="70%" class="no-uppercase" @click="goToDetailedView(item._id)">
  <span style="font-family: mainfont; font-size: 14px; color: white; letter-spacing: 1px; cursor: pointer;">View</span>
</v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-layout pt-4 pb-12 wrap justify-center>
          <v-flex xs12 align-self-center>
            <div>
              <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                v-model="currentPage" color="#13736f"></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <v-flex xs12 text-center>
        <span>No Orders!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
    
<script>
import axios from "axios";

export default {
  data() {
    return {
      list: [],
      currentPageCancelled: 1,
      itemsPerPage: 3,
     
      appLoading: false,
      msg: null,
      showSnackBar: false,
      currentPage: parseInt(localStorage.getItem("currentPage")) || 1,
      pages: 0,

    };
  },
  
  mounted() {
    this.getData();
    this.currentPage = parseInt(localStorage.getItem("currentPage")) || 1;
  },
  watch: {
    currentPage(newPage) {
      this.getData();
      localStorage.setItem("currentPage", newPage);

    },
    categoryId() {
      this.getList();
    },
    status() {
      this.getList();
    },
  },
  methods: {
    goToDetailedView(id) {
      this.$router.push({
          path: '/detailedView',
          query: {
              id: id,
              currentPage: this.currentPage
          }
      });
  },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/custom/order/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 3,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
    